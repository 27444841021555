<template>
  <div>
    <!-- allDetail -->
    <skeleton v-if="!goodsInfo" type="detail" />
    <div v-else>
      <div v-if="goodsStatus" class="goods-detail">
        <!-- 顶部和底部 -->
        <openApp
          :is-show-open-app="isLife !== 1"
          :showtig="true"
          :goods-status="goodsStatus"
          :goods-info="goodsInfo"
          :activity-id="activityId"
          :flash-info="flashInfo"
        />
        <!-- banner图片 -->
        <van-swipe :autoplay="3000" indicator-color="#ED2E2E">
          <van-swipe-item
            v-for="(image, index) in goodsInfo.banner"
            :key="index"
          >
            <img v-lazy="image" class="banner">
          </van-swipe-item>
        </van-swipe>
        <!-- 商品价格，商品名称，商品描述 -->
        <goodsInfo
          :flash-info="{}"
          :goods-status="goodsStatus"
          :goods-info="goodsInfo"
        />
        <div class="line" />
        <!-- 成长值，规格，区域，运费 -->
        <goodsHandle
          :goods-info="goodsInfo"
          :goods-status="goodsStatus"
          @getData="againGet"
        />
        <div class="line" />
        <!-- 商品评论 -->
        <AssessList
          v-if="comment.comment_total > 0"
          :comment="comment"
          :goods-i-d="goodsInfo.product_id"
          :show-more="true"
        />
        <div
          v-if="comment.result && comment.result.length > 0"
          class="line"
        />
        <!--5 推荐-->
        <groupDeRecommend :mend-goods="mendGoods" />
        <!-- 商品详情 -->
        <div class="goods-detail-info" style="width: 100%">
          <div class="tit">商品详情</div>
          <div style="width: 100%" v-html="goodContent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { ActionSheet, Icon, Lazyload, Swipe, SwipeItem } from 'vant'

Vue.use(Swipe).use(SwipeItem).use(Lazyload).use(Icon).use(ActionSheet)
import groupDeRecommend from '@/component/GoodsDetail/groupDeRecommend'
import {
  productInfo,
  productContent,
  productComment,
  getGoodsDetail
} from '@/services/goods.js'
import OpenApp from '@/component/GoodsDetail/goodsTopBtm'
import goodsInfo from '@/component/GoodsDetail/goodsInfo'
import goodsHandle from '@/component/GoodsDetail/goodsHandle'
import AssessList from '@/component/GoodsDetail/assessList'
import skeleton from '@/component/skeleton/index'
import { recommendGoods } from '@/services/api'

export default {
  name: 'SelfEmployedGoodDetail',
  components: {
    OpenApp,
    goodsInfo,
    goodsHandle,
    AssessList,
    skeleton,
    groupDeRecommend
  },
  data() {
    return {
      // 占空
      flashInfo: {
        state: ''
      },
      goodsStatus: 1, // 1:自营详情；2:秒杀详情；3:京东详情；4:拼多多详情；5:淘宝详情；6:唯品会详情 7:拼团
      liveId: this.$route.query.liveid || 0, // 直播ID    修正 liveId
      room_id: this.$store.state.room_id || 0, // 关系ID
      robot_send: this.$store.state.robot_send || 0, // 机器人
      liveUserId: this.$route.query.liveUserId || 0, // 直播人ID
      productType: this.$route.query.parentTypeId || 0, // 产品类型
      productId: this.$route.query.goodsId || this.$route.query.product_id || 0, // 产品ID
      productSkuId:
        this.$route.query.skuid || this.$route.query.product_sku_id || 0, // 产品规格ID
      uid: window.localStorage.getItem('uid') || 0, // 用户ID
      goodContent: '',
      comment: {},
      goodsInfo: null, // 商品信息
      isReady: false, // 详情接口是否请求完成
      activityId: '',
      mendGoods: '',

      isLife: null // 仰生活判断
    }
  },
  mounted() {
    // eslint-disable-next-line eqeqeq
    if (this.$route.query.status && this.$route.query.status == '7') {
      this.goodsStatus = Number(this.$route.query.status || 7)
    }
    const { codeNumber } = this.$route.query
    localStorage.setItem('shareCodeNumber', codeNumber && codeNumber !== 'undefined' ? codeNumber : '')
    this.isLife = Number(this.$route.query.isLife)
    this.$store.commit('changeInterceptUrl', window.location.href)
    // eslint-disable-next-line eqeqeq
    if (this.$route.query.status == '7') {
      this.getPtGoodsDetail()
    } else {
      this.getGoodsDetail()
    }

    // goodsStatus = Number(this.$route.query.goodsStatus || 7);
    this.dataList()
  },
  created: function() {
    const live_id = this.$route.query.liveid || 0
    sessionStorage.setItem('live_id', live_id)
    this.activityId = this.$route.query.activityId
  },
  methods: {
    // 领完卷再次调接口
    againGet() {
      this.getGoodsDetail()
    },
    // 推荐 list
    dataList: function() {
      const data = {
        goodsId: this.$route.query.goodsId,
        uid: window.localStorage.getItem('uid')
      }
      recommendGoods(data).then((res) => {
        if (Number(res.code) === 200) {
          this.mendGoods = res.data
        }
      })
    },

    getPtGoodsDetail() {
      const data = {
        id: this.$route.query.id,
        uid: this.uid // 用户ID
      }

      getGoodsDetail(data).then((res) => {
        if (Number(res.code) === 200) {
          res.data.productInfo.id = res.data.assembleInfo.id
          res.data.productInfo.showPrice = res.data.assembleInfo.team_price
          res.data.productInfo.priceName = '单独购'
          res.data.productInfo.goodPrice = res.data.assembleInfo.origin_price
          res.data.productInfo.areaInfo = res.data.areaInfo
          res.data.productInfo.assembleInfo = res.data.assembleInfo
          res.data.productInfo.needer = res.data.assembleInfo.needer

          this.goodContent = res.data.productInfo.goodContent
          this.goodsInfo = res.data.productInfo
        }
      })
    },

    getGoodsDetail() {
      const shareId = localStorage.getItem('shareCodeNumber')
      const data = {
        shareId,
        liveId: this.$route.query.liveid || this.$route.query.live_id || 0, // 直播ID
        live_id: this.$route.query.liveid || this.$route.query.live_id || 0, // 直播ID
        liveUserId: this.liveUserId, // 直播人ID
        productType: this.productType, // 产品类型
        product_id: this.productId, // 产品ID
        product_sku_id: this.productSkuId, // 产品规格ID
        room_id: this.room_id,
        robot_send: this.robot_send,
        live_people_id:
          this.$route.query.live_people_id ||
          this.$route.query.live_mid ||
          this.$store.state.live_people_id ||
          '', // 主播ID
        uid: this.uid // 用户ID
      }

      productInfo(data).then((res) => {
        if (Number(res.code) === 200) {
          res.data.banner = res.data.banner || []
          res.data.saleList = res.data.saleList || []
          this.goodsInfo = res.data
          this.isReady = true
        } else {
          this.$router.go(-1)
        }
      })
      const conData = {
        product_id: this.productId
      }
      productContent(conData).then((res) => {
        if (Number(res.code) === 200) {
          this.goodContent = res.data.intro
        }
      })
      const CommentData = {
        product_id: this.productId,
        page: 9999,
        pagesize: 10
      }
      productComment(CommentData).then((res) => {
        if (Number(res.code) === 200) {
          this.comment = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ssd-module-wrap {
  div {
    width: auto;
    height: auto;
  }
}

.goods-detail {
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  padding-bottom: 75px;
  overflow-x: hidden;

  .banner {
    width: 100%;
    height: 357px;
    background: #999;
  }

  .line {
    width: 100%;
    height: 7px;
    background: #f8f8f8;
  }

  .goods-detail-info {
    width: 100%;
    background: #fff;

    .tit {
      width: 100%;
      height: 38px;
      line-height: 38px;
      text-align: center;
      font-size: 15px;
      color: #141414;
    }

    ::v-deep img {
      display: block;
      width: 100%;
    }
  }
}
</style>
